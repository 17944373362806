import React from 'react';

export default function Button({ children, variant = 'primary', className = '', ...props }) {
  const baseStyles = {
    primary: 'btn-primary px-4 py-2',
    whatsapp: 'btn-whatsapp px-4 py-2',
  };

  return (
    <button
      className={`${baseStyles[variant]} ${className}`}
      {...props}
    >
      {children}
    </button>
  );
}