// src/home/frontend/src/components/common/image.js
import React, { useState, useEffect } from 'react';

const Image = ({ src, alt, className, onError, initialData, ...props }) => {
  const [imageSrc, setImageSrc] = useState(() => {
    if (initialData?.data?.storage_url) {
      // Clean the source path by removing leading slash and static/frontend
      const cleanPath = src.replace(/^\//, '')
                          .replace(/^static\/frontend\//, '');
      return `${initialData.data.storage_url}${cleanPath}`;
    }
    return src;
  });

  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    // Update image source if initialData changes
    if (initialData?.data?.storage_url) {
      const cleanPath = src.replace(/^\//, '')
                          .replace(/^static\/frontend\//, '');
      setImageSrc(`${initialData.data.storage_url}${cleanPath}`);
    }
  }, [initialData, src]);

  const handleError = (e) => {
    if (!hasError) {
      setHasError(true);
      console.error(`Failed to load image from: ${imageSrc}`);
      
      // Try fallback to local path in development
      if (process.env.NODE_ENV === 'development') {
        const fallbackPath = `/static/frontend/${src.replace(/^\//, '')}`;
        // console.log('Trying fallback path:', fallbackPath);
        setImageSrc(fallbackPath);
      }
    }
    if (onError) onError(e);
  };

  if (hasError) {
    return (
      <div className={`flex items-center justify-center ${className}`}>
        <div className="bg-gray-100 rounded p-2 text-xs text-gray-500">
          Image Failed to Load
        </div>
      </div>
    );
  }

  return (
    <img 
      src={imageSrc}
      alt={alt}
      onError={handleError}
      className={className}
      {...props}
    />
  );
};

export default Image;