import React, { useState } from 'react';
import Marquee from 'react-fast-marquee';

import Layout from '../components/layout/layout';
import Container from '../components/common/container';
import Hero from '../components/home/sections/hero';
import Stats from '../components/home/sections/stats';
import Features from '../components/home/sections/features';
import Conversion from '../components/home/sections/conversion';
import Image from '../components/common/image';
import { getPartnerLogos } from '../config/images';

export default function Home({ initialData }) {
  const [showDeadstock, setShowDeadstock] = useState(false);
  const partnerLogos = getPartnerLogos(initialData);

  const openWhatsApp = () => {
    const url = "intent://send/?phone=254105279316#Intent;scheme=whatsapp;end";
    window.location.href = url;
  };

  return (
    <Layout initialData={initialData}>
      {/* Hero Section */}
      <Hero showDeadstock={showDeadstock} setShowDeadstock={setShowDeadstock} initialData={initialData} />
      {/* Partner Logos */}
      <div className="bg-primary py-6 md:py-8 mt-8 md:mt-16">
          <Container>
            <Marquee
              gradient={false}
              speed={40}
              pauseOnHover={true}
              className="opacity-70"
            >
              {partnerLogos.map((logo, index) => (
                <Image
                  key={index}
                  src={logo}
                  alt={`Partner ${index + 1}`}
                  className="h-6 md:h-8 grayscale mx-8"
                  initialData={initialData}
                  onError={(e) => {
                    console.error(`Failed to load image: ${logo}`);
                  }}
                />
              ))}
            </Marquee>
          </Container>
      </div>
      <Stats showDeadstock={showDeadstock} initialData={initialData} />
      <Conversion showDeadstock={showDeadstock} initialData={initialData} />
      <Features showDeadstock={showDeadstock} initialData={initialData} />
      {/* WhatsApp Chat Button */}
      <button onClick={openWhatsApp} className="fixed bottom-4 right-4 md:bottom-6 md:right-6 bg-whatsapp hover:bg-whatsapp-hover text-white rounded-full px-4 py-2 md:px-6 md:py-3 flex items-center gap-2 text-sm md:text-base shadow-lg">
        <span className="text-lg md:text-xl">💬</span>
        Run Basic Test
      </button>
    </Layout>
  );
}