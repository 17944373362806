// const getBasePath = (initialData) => {
//   if (initialData?.data?.storage_url) {
//     return initialData.data.storage_url;
//   }
//   return process.env.NODE_ENV === 'development' ? '' : '/static/frontend/';
// };

const getCompanyLogos = (initialData) => {
  const logos = {
    freshworks: 'images/logos/companies/freshworks.png',
    jobtech: 'images/logos/companies/jobtechalliance.png',
    kenia: 'images/logos/companies/kenia.png',
    foundershub: 'images/logos/companies/msforstartups.png',
  };

  return Object.keys(logos).reduce((acc, key) => {
    acc[key] = logos[key]; // Don't append BASE_PATH here
    return acc;
  }, {});
};

export const getPartnerLogos = (initialData) => {
  const logos = getCompanyLogos(initialData);
  return Object.values(logos);
};

export const getLogoSections = (initialData) => {
  return {
    partners: getPartnerLogos(initialData),
  };
};

export default getCompanyLogos;