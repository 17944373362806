import React from 'react';
import Container from '../../common/container';

export default function Stats({ showDeadstock = false, initialData }) {
  const metricsData = {
    default: {
      title: 'Transform Chats into Commerce',
      subtitle: 'Where conversations flow naturally into transactions',
      cta: 'Join thousands of businesses revolutionizing their customer engagement',
      ctaButton: 'Start Your Journey',
      ctaButtonUrl: 'https://wymbee.com/account/signin/',
      attribution: 'WhatsApp Research 2022',
      metrics: [
        {
          value: '3min',
          label: 'Lightning Fast Response',
          description: 'Average time for customers to read and respond to messages',
          highlight: true
        },
        {
          value: '3x',
          label: 'Revenue Growth',
          description: 'Higher conversion rates through chat commerce vs traditional e-commerce',
          highlight: false
        },
        {
          value: '89%',
          label: 'Customer Retention',
          description: 'of customers prefer buying from businesses they can message directly',
          highlight: true
        }
      ]
    },
    deadstock: {
      title: 'Efficient Inventory Management',
      subtitle: 'Turn your excess stock into opportunity with our community-driven platform',
      cta: 'Join businesses optimizing their inventory management',
      ctaButton: 'Download App',
      ctaButtonUrl: 'https://play.google.com/store/apps/details?id=com.app.wymbee',
      attribution: 'Market Research 2023',
      metrics: [
        {
          value: 'Free',
          label: 'Point of Sale System',
          description: 'Modern inventory management and POS system at zero cost',
          highlight: true
        },
        {
          value: '500+',
          label: 'Business Network',
          description: 'Connected businesses in our community for efficient redistribution',
          highlight: true
        },
        {
          value: '48hrs',
          label: 'Quick Reallocation',
          description: 'Average time to find buyers for your deadstock inventory',
          highlight: false
        }
      ]
    }
  };

  const activeData = metricsData[showDeadstock ? 'deadstock' : 'default'];

  return (
    <section className="bg-gradient-to-b from-white to-gray-50/50 py-16 lg:py-24">
      <Container>
        {/* Section Header */}
        <div className="text-center mb-12 md:mb-16">
          <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4">
            {activeData.title}
          </h2>
          <p className="text-gray-600 text-base md:text-lg max-w-2xl mx-auto">
            {activeData.subtitle}
          </p>
        </div>

        {/* Metrics Grid */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-12">
          {activeData.metrics.map((metric, index) => (
            <div 
              key={index}
              className="relative group"
            >
              {/* Default gradient background - now always visible */}
              <div className="absolute inset-0 bg-gradient-to-br from-primary/5 to-primary/10 rounded-2xl" />
              
              {/* Glow effect on hover */}
              <div className="absolute inset-0 bg-primary/0 group-hover:bg-primary/5 rounded-2xl blur-xl transition-all duration-300" />
              
              <div className="relative bg-white/80 backdrop-blur-sm rounded-2xl p-6 md:p-8 text-center border border-gray-100 hover:border-primary/20 transition-all duration-300 hover:shadow-lg hover:shadow-primary/5">
                {/* Metric Value */}
                <div className="inline-flex items-center justify-center">
                  <span className="text-4xl md:text-5xl lg:text-6xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-primary to-primary/80">
                    {metric.value}
                  </span>
                </div>
                
                {/* Metric Label */}
                <h3 className="text-lg md:text-xl font-semibold my-3 text-gray-900">
                  {metric.label}
                </h3>
                
                {/* Metric Description */}
                <p className="text-gray-600 text-sm md:text-base leading-relaxed">
                  {metric.description}
                </p>

                {/* Bottom accent line - now always visible with enhanced hover effect */}
                <div className="absolute bottom-0 left-1/2 -translate-x-1/2 w-16 h-1 bg-gradient-to-r from-primary/0 via-primary/30 to-primary/0 transform -translate-y-2 opacity-40 group-hover:opacity-100 group-hover:via-primary/60 transition-all duration-500" />
              </div>
            </div>
          ))}
        </div>

        {/* Bottom CTA */}
        <div className="mt-16 text-center">
          <p className="text-gray-600 mb-6">
            {activeData.cta}
          </p>
          <a 
            href={activeData.ctaButtonUrl} 
            target="_blank" 
            rel="noopener noreferrer"
            className="inline-flex items-center justify-center gap-2 text-primary hover:text-primary-hover transition-colors duration-200 group cursor-pointer"
          >
            <span className="font-medium">{activeData.ctaButton}</span>
            <svg 
              className="w-4 h-4 transform group-hover:translate-x-1 transition-transform duration-200" 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </a>
        </div>

        {/* Subtle Attribution */}
        <div className="mt-8 text-center text-sm text-gray-500">
          <p className="flex items-center justify-center gap-2">
            <span className="w-1 h-1 rounded-full bg-primary/40" />
            {activeData.attribution}
            <span className="w-1 h-1 rounded-full bg-primary/40" />
          </p>
        </div>
      </Container>
    </section>
  );
}