import React from 'react';
import Container from '../../common/container';

export default function Features({ showDeadstock = false, initialData }) {
  const featuresData = {
    default: {
      title: "Why Choose Our Platform",
      subtitle: "Streamline your business operations and delight your customers",
      features: [
        {
          title: "Simplified Sales Process",
          description: "Turn conversations into conversions with our streamlined chat-based selling tools",
          icon: (
            <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
            </svg>
          )
        },
        {
          title: "Intelligent Automation",
          description: "Build flows that handle routine tasks while you focus on growing your business",
          icon: (
            <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" />
            </svg>
          )
        },
        {
          title: "Enhanced Customer Experience",
          description: "Create memorable shopping experiences that keep customers coming back",
          icon: (
            <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          )
        },
        {
          title: "Smart Inventory Management",
          description: "Real-time stock updates and automated reordering to prevent stockouts",
          icon: (
            <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4" />
            </svg>
          )
        }
      ],
      notifications: [
        {
          icon: "🛍️",
          title: "New order received!",
          time: "2 minutes ago",
          bgColor: "bg-gray-50"
        },
        {
          icon: "✓",
          title: "Payment confirmed",
          time: "Just now",
          bgColor: "bg-green-50"
        },
        {
          icon: "📦",
          title: "Inventory updated automatically",
          time: "1 minute ago",
          bgColor: "bg-blue-50"
        }
      ]
    },
    deadstock: {
      title: "Smart Inventory Solutions",
      subtitle: "Transform deadstock challenges into business opportunities",
      features: [
        {
          title: "Market Analytics",
          description: "AI-powered analysis to identify optimal markets for your excess inventory",
          icon: (
            <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z" />
            </svg>
          )
        },
        {
          title: "Business Community",
          description: "Connect with a network of 500+ retailers to redistribute inventory effectively",
          icon: (
            <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
            </svg>
          )
        },
        {
          title: "Free POS System",
          description: "Comprehensive inventory management with our zero-cost point of sale system",
          icon: (
            <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 7h6m0 10v-3m-3 3h.01M9 17h.01M9 14h.01M12 14h.01M15 11h.01M12 11h.01M9 11h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
            </svg>
          )
        },
        {
          title: "Inventory Optimization",
          description: "Maximize returns through smart reallocation instead of heavy discounting",
          icon: (
            <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          )
        }
      ],
      notifications: [
        {
          icon: "📊",
          title: "New market opportunity detected!",
          time: "2 minutes ago",
          bgColor: "bg-gray-50"
        },
        {
          icon: "🤝",
          title: "Match found for excess inventory",
          time: "Just now",
          bgColor: "bg-green-50"
        },
        {
          icon: "💰",
          title: "Potential revenue increase identified",
          time: "1 minute ago",
          bgColor: "bg-blue-50"
        }
      ]
    }
  };

  const activeData = featuresData[showDeadstock ? 'deadstock' : 'default'];

  return (
    <section className="py-16 lg:py-24 relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-b from-white to-gray-50/30" />
      
      <Container className="relative">
        <div className="grid lg:grid-cols-2 gap-12 lg:gap-20 items-center">
          {/* Left Column - Features List */}
          <div className="space-y-12">
            <div className="space-y-4">
              <h2 className="text-3xl md:text-4xl font-bold">
                {activeData.title}
              </h2>
              <p className="text-gray-600 text-lg">
                {activeData.subtitle}
              </p>
            </div>

            <div className="space-y-8">
              {activeData.features.map((feature, index) => (
                <div 
                  key={index}
                  className="flex items-start gap-4 group hover:translate-x-2 transition-transform duration-300"
                >
                  <div className="flex-shrink-0 w-12 h-12 rounded-lg bg-primary/5 group-hover:bg-primary/10 flex items-center justify-center text-primary transition-colors duration-300">
                    {feature.icon}
                  </div>
                  <div className="space-y-2">
                    <h3 className="text-xl font-semibold">
                      {feature.title}
                    </h3>
                    <p className="text-gray-600">
                      {feature.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Right Column - Interactive Demo */}
          <div className="relative">
            <div className="bg-white rounded-2xl shadow-xl p-4 md:p-6 relative z-10">
              <div className="space-y-4">
                {activeData.notifications.map((notification, index) => (
                  <div key={index} className={`flex items-center gap-3 p-3 ${notification.bgColor} rounded-lg animate-fade-in`} style={{ animationDelay: `${index * 150}ms` }}>
                    <div className="w-8 h-8 rounded-full bg-primary/10 flex items-center justify-center text-primary">
                      {notification.icon}
                    </div>
                    <div className="flex-1">
                      <p className="text-sm font-medium">{notification.title}</p>
                      <p className="text-xs text-gray-500">{notification.time}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="absolute -right-4 -bottom-4 w-full h-full bg-gradient-to-br from-primary/20 to-primary/5 rounded-2xl -z-10" />
            <div className="absolute -left-4 -top-4 w-full h-full bg-gradient-to-tl from-primary/10 to-primary/0 rounded-2xl -z-10" />
          </div>
        </div>
      </Container>
    </section>
  );
}