import React from 'react';
import Container from '../../common/container';
import Button from '../../common/button';

export default function Hero({ showDeadstock, setShowDeadstock, initialData }) {
    const getImagePath = (path) => {
        // Ensure the storage URL is used if available
        if (initialData?.data?.storage_url) {
            // Remove any redundant prefixes like '/static/frontend/' to prevent duplication
            const sanitizedPath = path.startsWith('/static/frontend/')
                ? path.replace('/static/frontend/', '')
                : path;
            return `${initialData.data.storage_url}${sanitizedPath}`;
        }
        // Fallback to the original path if storage_url is unavailable
        return path;
    };
    
    const content = {
      default: {
        title: 'Engage Clients On The World\'s',
        titleHighlight: 'Most Popular Apps',
        description: "Revolutionize how your business connects, sells, and grows by integrating seamlessly with the world's most popular messaging platforms. Leverage WhatsApp & Telegram on SokoGram to build meaningful relationships with clients and grow your business.",
        trustText: "Built atop the world's ",
        trustHighlight: 'most popular',
        trustEnd: ' messaging platforms.',
        image: getImagePath('/static/frontend/images/site/social_commerce.png'),
        imageAlt: 'WymBee Dashboard'
      },
      deadstock: {
        title: 'One-Stop Solution To Your',
        titleHighlight: 'Deadstock Problem',
        description: 'Transform your excess inventory into opportunity. Our intelligent platform connects you with potential buyers, optimizes pricing, and streamlines the entire redistribution process for maximum recovery.',
        trustText: 'Successfully redistributed ',
        trustHighlight: 'KES 5M+ worth',
        trustEnd: ' of deadstock inventory across Kenya.',
        image: getImagePath('/static/frontend/images/site/appdemo.png'),
        imageAlt: 'WymBee App'
      }
    };
  
    const activeContent = content[showDeadstock ? 'deadstock' : 'default'];
  
    return (
        <Container className="py-12 md:py-20 relative">
        {/* Toggle Switch */}
        <div className="fixed top-24 right-4 flex items-center gap-3 bg-white/80 backdrop-blur-sm p-2 rounded-full shadow-md z-50">
            <span className={`text-sm transition-colors duration-300 ${!showDeadstock ? 'text-primary font-medium' : 'text-gray-500'}`}>
                Social Commerce
            </span>
            <button
                onClick={() => setShowDeadstock(!showDeadstock)}
                className="relative w-14 h-7 rounded-full transition-colors duration-300"
                style={{
                    backgroundColor: showDeadstock ? '#7E22CE' : '#F3F4F6'
                }}
            >
                <div
                    className="absolute top-1 left-1 w-5 h-5 bg-white rounded-full shadow-md transition-transform duration-300 ease-in-out"
                    style={{
                        transform: showDeadstock ? 'translateX(28px)' : 'translateX(0)'
                    }}
                />
            </button>
            <span className={`text-sm transition-colors duration-300 ${showDeadstock ? 'text-primary font-medium' : 'text-gray-500'}`}>
                Deadstock Solution
            </span>
        </div>

        <div className="flex flex-col lg:flex-row items-center gap-8 lg:gap-16 pt-8 md:pt-12">
            <div className="max-w-xl">
                <h1 className="font-bold mb-4 md:mb-6">
                    <div className="text-4xl md:text-5xl lg:text-6xl">
                    {activeContent.title}
                    </div>
                    <div className="text-3xl md:text-4xl lg:text-5xl text-primary mt-3">
                    {activeContent.titleHighlight}
                    </div>
                </h1>
                <p className="text-gray-600 text-base md:text-xl mb-6 md:mb-8">
                    {activeContent.description}
                </p>
                {showDeadstock ? (
                    <a href="https://play.google.com/store/apps/details?id=com.app.wymbee" target="_blank" rel="noopener noreferrer">
                        <button 
                            className="w-full md:w-auto text-lg px-6 md:px-8 py-3 md:py-4 bg-primary hover:bg-primary-hover text-white rounded transition-colors duration-200 flex items-center justify-center gap-2"
                            >
                            <span>Download App</span>
                            <svg 
                                viewBox="0 0 24 24" 
                                className="w-6 h-6 fill-current"
                            >
                                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 14v-4H8l4-4 4 4h-3v4h-2z"/>
                            </svg>
                        </button>
                    </a>
                ) : (
                    <a href="https://calendar.app.google/BXgPp5Ks5SyhBHLbA" target="_blank" rel="noopener noreferrer">
                        <Button className="w-full md:w-auto text-lg px-6 md:px-8 py-3 md:py-4 bg-[#25D366] hover:bg-[#128C7E] text-white rounded transition-colors duration-200">
                        Book Demo Session
                        </Button>
                    </a>
                )}
                <div className="mt-6 md:mt-8 text-gray-700 text-sm md:text-base">
                    {activeContent.trustText}
                    <span className="text-primary font-bold">
                    {activeContent.trustHighlight}
                    </span>
                    {activeContent.trustEnd}
                </div>
            </div>

            {/* Dashboard Preview with fade transition */}
            <div className="relative w-full lg:w-1/2">
                <div className="relative bg-white rounded-lg shadow-xl p-2 md:p-4 transition-opacity duration-300">
                    {/* Fixed aspect ratio container */}
                    <div className="relative w-full max-w-full overflow-hidden" style={{ maxHeight: "480px" }}>
                        <div className="aspect-w-16 aspect-h-10 relative">
                            <img 
                                key={activeContent.image}
                                src={activeContent.image}
                                alt={activeContent.imageAlt}
                                className="rounded-lg w-full h-full object-contain p-2"
                                style={{ 
                                    maxHeight: "100%",
                                    maxWidth: "100%",
                                }}
                            />
                        </div>
                    </div>
                    
                    {/* Optional subtle gradient overlay */}
                    <div className="absolute inset-0 rounded-lg bg-gradient-to-t from-gray-50/50 to-transparent pointer-events-none" />
                </div>
                
                {/* Decorative background elements */}
                <div className="absolute -inset-3 bg-gradient-to-tr from-primary/5 via-primary/3 to-transparent rounded-xl blur-xl -z-10" />
            </div>
        </div>
        </Container>
    );
}